import React from "react";

import {
  Box,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import VideoPlayButton from "assets/svg/video-play-button.svg";

function CTA() {
  return (
    <Container
      size="md"
      pt={{ base: "5rem", md: "8.125rem" }}
      pb={{ base: "2.75rem", md: "4.5rem", xl: "7.75rem" }}
    >
      <Stack
        direction={{ base: "column", xl: "row" }}
        align="center"
        justify="center"
        spacing="4.375rem"
      >
        <Box position="relative" borderRadius="2.5rem" overflow="hidden">
          <iframe
            src="https://www.dropbox.com/scl/fi/efuf1ue8ag0xgmhc5o30k/landlordinyumba.mp4?rlkey=h1x5ak5tgqioma9cy664rw9xe&st=g73i4hrx&raw=1"
            width="100%"
            height="400px"
            style={{
              borderRadius: "2.5rem",
              border: "none",
            }}
            allow="autoplay; fullscreen;loop"
          ></iframe>
        </Box>

        {/* Text */}
        <VStack align="stretch" maxW="429px">
          {/* Headings */}
          <VStack spacing="0.5rem" align="stretch">
            <Text
              fontWeight="700"
              color="red.400"
              textAlign={{ base: "center", xl: "start" }}
            >
              SAVE MORE TIME
            </Text>
            <Heading
              as="h2"
              size="lg"
              textAlign={{ base: "center", xl: "start" }}
            >
              And Boost Productivity
            </Heading>
          </VStack>
          {/* Text */}
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Your dont need tenants sending their mpesa messages as proof of
            payment. Check your page and see clients who have paid.
            Automatically advertise empty rentals in the platform.
          </Text>
          <VStack align="stretch" maxW="429px">
            {/* Headings */}
            <Heading
              as="h2"
              size="lg"
              textAlign={{ base: "center", xl: "start" }}
            >
              Contact us
            </Heading>
          </VStack>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Email: customerservice@inyumba.co.ke
          </Text>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Phone Number: 0742284044
          </Text>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Alternative Number: 0757930647
          </Text>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Instagram: inyumba.co.ke
          </Text>
          <Heading
            as="h2"
            size="lg"
            textAlign={{ base: "center", xl: "start" }}
          >
            Tenant Download android app
          </Heading>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            <a
              href="https://www.dropbox.com/scl/fi/kdwjrv2pg6ikn7z896v15/app-release.apk?rlkey=48nl8onc89mwzizjn7fv3jr89&st=09eczk8s&dl=1"
              // target="_blank"
              rel="noopener noreferrer"
            >
              Click here to download.
            </a>
          </Text>

          <Heading
            as="h2"
            size="lg"
            textAlign={{ base: "center", xl: "start" }}
          >
            Terms and Conditions
          </Heading>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Please review our terms and conditions and only register or use our
            platform if you are comfortable with them.{" "}
            <a
              href="https://backend.inyumba.co.ke/termsandconditions/termsandconditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to see terms and conditions.
            </a>{" "}
          </Text>
        </VStack>
      </Stack>
    </Container>
  );
}

export default CTA;
