import React from "react";
import { Container, Hide, Show, Text, Wrap, WrapItem } from "@chakra-ui/react";

import LogoAdobe from "assets/svg/logo-adobe.svg";
import LogoApple from "assets/svg/logo-apple.svg";
import LogoGoogle from "assets/svg/logo-google.svg";
import LogoSlack from "assets/svg/logo-slack.svg";
import LogoSpotify from "assets/svg/logo-spotify.svg";

function LogoCloud() {
  return (
    <Container size="md" pt={{ base: "3.125rem", md: "4.375rem" }}>
      <Wrap
        justify={{ sm: "start", md: "center", lg: "space-between" }}
        align="center"
        spacing="1.55rem"
      >
        {/* Heading */}
        <WrapItem>
          <Show above="lg">
            <Text fontWeight="600">
              Trusted by
              <br />
              100% of landlords
            </Text>
          </Show>
          <Show below="lg">
            <Text fontWeight="600">Trusted by Global Companies</Text>
          </Show>
        </WrapItem>
        {/* Logos */}
      </Wrap>
    </Container>
  );
}

export default LogoCloud;
