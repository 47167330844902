import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Container size="lg" py="2.5rem">
      <HStack>
        <Box w="100%" position="relative">
          <Link
            as={NavLink}
            to="/"
            transform="auto"
            translateY="-0.2rem"
            _focus={{ boxShadow: "none" }}
          ></Link>
        </Box>
        <Popover isOpen={isOpen} onClose={handleClose}>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              fontWeight="400"
              onClick={() => setIsOpen(!isOpen)}
            >
              Logins
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Logins</PopoverHeader>
            <PopoverBody>
              <Link href="https://caretaker.inyumba.co.ke" isExternal>
                Caretaker
              </Link>
              <br />
              <Link href="https://landlord.inyumba.co.ke" isExternal>
                Landlord
              </Link>
              <br />
              <Link href="https://tenant.inyumba.co.ke" isExternal>
                Tenant
              </Link>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    </Container>
  );
}

export default NavBar;
