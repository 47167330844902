import React from "react";

import { Container, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import {
  FiBriefcase,
  FiGift,
  FiGlobe,
  FiSend,
  FiTruck,
  FiTool,
  FiCreditCard,
  FiTrello,
  FiArchive,
  FiDollarSign,
  FiHome,
  FiClipboard,
  FiPhone,
  FiTrendingUp,
  FiFile,
  FiSettings,
} from "react-icons/fi";

import Feature from "./Feature";

const featuresData = [
  {
    icon: FiClipboard, // Corrected icon for "Payment statuses"
    iconBg: "#F75C4E",
    heading: "Payment statuses",
    text: "Have payment records and see who has paid or not.",
  },
  {
    icon: FiHome, // Corrected icon for "One dashboard-Many Properties"
    iconBg: "#5C4EF7",
    heading: "One dashboard-Many Properties",
    text: "Manage many properties while on one dashboard.",
  },
  {
    icon: FiPhone, // Corrected icon for "Remote Mode"
    iconBg: "#191046",
    heading: "Remote Mode",
    text: "Manage your property from your home.",
  },
  {
    icon: FiSettings, // Corrected icon for "Maintenance Requests"
    iconBg: "#F7954E",
    heading: "Maintenance Requests",
    text: "Check on maintenance requests made by your tenants.",
  },
  {
    icon: FiGlobe, // Corrected icon for "Advertise Properties"
    iconBg: "#FF1FB3",
    heading: "Advertise Properties",
    text: "Automatic Advertisement of your vacant rentals in your properties.",
  },
  {
    icon: FiDollarSign, // Corrected icon for "Rates"
    iconBg: "#F7954E",
    heading: "Rates",
    text: "We offer very flexible rates.",
  },
];

function Features() {
  return (
    <Container size="md" pt={{ base: "5rem", md: "8.125rem" }}>
      <VStack spacing={{ base: "3rem", md: "4.25rem" }}>
        {/* Heading */}
        <VStack spacing="1.25rem">
          <VStack spacing="0.5rem">
            <Text fontWeight="700" color="red.400" textAlign="center">
              MANAGE YOUR BUSSINESS
            </Text>
            <Heading as="h2" size="lg" textAlign="center">
              While on your couch
            </Heading>
          </VStack>
        </VStack>
        {/* Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingY="3.75rem"
          spacingX="5.25rem"
          maxW="1105px"
        >
          {featuresData.map((featureData, index) => (
            <Feature
              key={index}
              icon={featureData.icon}
              iconBg={featureData.iconBg}
              heading={featureData.heading}
              text={featureData.text}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
}

export default Features;
